.container {
  max-width: 1170px;
}

.pageName {
  margin-bottom: 60px;
}

.pageName h2 {
  margin: 0;
  margin-bottom: 5px;
  font-size: 58px;
  font-family: "Source", sans-serif;
  font-weight: 480;
}

.contact {
  margin-bottom: 30px;
  margin-top: 40px;
}

.location {
  font-weight: 600;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.location a {
  color: var(--blue);
  font-weight: 400;
  text-decoration: none;
}

.contactForm,
.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contactForm #input {
  font-size: 12px;
  width: 100%;
}

.contactForm #textarea {
  width: 61em;
  height: 30em;
  border-radius: 5px;
  border: white;
  width: 100%;
}

.form-font2 h6,
.form-font p {
  font: xx-small;
}

.contactForm h4 {
  font-weight: bold;
}

h6.form-font2 {
  font-weight: bold;
  padding-bottom: 5%;

  font-family: "Lato", sans-serif;
}

/*************************************************************/

.input-wrapper {
  position: relative;
}

.form-control {
  display: block;
  line-height: 2em;
  margin: 0;
  padding-left: 10px;
  width: 100%;
  font-size: medium;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 500;
}

.form-control:focus {
  border: 1px solid #2c7ac9;
}

.control-label {
  display: block;
  position: absolute;
  opacity: 0;
  /* bottom: 1.9rem; */
  top: 0.92rem;
  color: #5d5d5d;
  transition: 0.2s ease-in-out transform;
  font-size: 14px;
}

.form-control:placeholder-shown + .control-label {
  visibility: hidden;
  z-index: -1;
  transition: 0.2s ease-in-out;
}

.form-control:not(:placeholder-shown) + .control-label,
.form-control:focus:not(:placeholder-shown) + .control-label {
  visibility: visible;
  z-index: 1;
  opacity: 1;
  transform: translateY(-35px);
  transition: 0.2s ease-in-out transform;
}

/*************************************************************/

.form-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 5%;
}

.form-container input {
  height: 2rem;
  /* padding: 0 1rem; */
  margin-bottom: 2rem;
  background: #dcdcdc;
  border: none;
}

.form-container textarea {
  /* padding: 1rem; */
  margin-bottom: 1rem;
  background: #dcdcdc;
  border: none;
  font-family: "Roboto";
}

textarea {
  resize: none;
}

.btn-container .CTA {
  border: 0px;
}

.btn-container {
  display: flex;
  justify-content: center;
  border: 0px;
}

@media (min-width: 821px) {
  @media (max-width: 444px) {
    form {
      width: 100%;
      margin: 0px;
    }
  }

  .form-container {
    flex-direction: row;
    align-items: unset;
  }

  .location {
    width: 30%;
  }

  .contactForm {
    width: 70%;
  }

  .btn-container {
    justify-content: unset;
  }
}
