.prod {
  margin-block: 30px;
}

.prod__title h1 {
  font-size: 48px;
}

.prod__img {
  margin-block: 40px;
}

.prod__img img {
  width: 100%;
  border-radius: 0 0 0 15px;
}

.prod__content p {
  color: #6c6c6c;
  font-weight: 500;
  font-size: 16;
  margin-block: 40px;
}

.prod__categories-title {
  font-size: 30px;
}

.prod__img span {
  height: 100%;
}

.prod__categories-items {
  margin-block: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}
/* Tablet */
@media (min-width: 641px) {
  .prod__categories-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .prod__categories-items__item {
    margin-inline: auto;
  }

  .prod__categories-title {
    font-size: 50px;
  }
}

/* Desktop */
@media (min-width: 1201px) {
  .prod__categories-items {
    grid-template-columns: repeat(3, 1fr);
    margin-inline: unset;
  }

  .prod__categories-items__item {
    margin-inline: unset;
  }

  .cdo {
    display: flex;
    flex-direction: row-reverse;
    /* align-items: center; */
    height: 500px;
    gap: 20px;
  }

  .prod__img {
    flex: 1;
    margin-block: unset;
  }

  .prod__img img {
    height: 100%;
    /* object-fit: cover; */
  }

  .prod__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
  }

  .prod__content h1 {
    font-size: 68px;
  }

  .prod__categories-items__item {
    width: 275px;
    height: 330px;
    /* margin-inline: auto; */
  }

  .center {
    margin-inline: auto;
    background-color: blue;
  }

  .end {
    margin-left: auto !important;
  }
}
