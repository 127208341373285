:root {
  --blue: #1c6eda;
  --secondary: #d3735d;
  --complementary: #6eda1c;
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: url("./fonts/Roboto-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: url(./fonts/Roboto-Medium.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  src: url("./fonts/Roboto-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Olarwe";
  src: url("./fonts/OlarweRegular-nRev1.ttf");
  font-display: swap;
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  background-color: #fafbff;
  font-family: "Roboto" sans-serif !important;
}

/* p,
h1 {
  margin: 0;
} */
@media screen and (max-width: 775px) {
  .hide-for-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 776px) {
  .hide-for-desktop {
    display: none !important;
  }
}

.ruler {
  border-bottom: 6px solid var(--secondary);
  width: 100px;
  float: left;
}

.CTA {
  display: inline-block;
  /* background-color: var(--blue); */
  background: linear-gradient(to right, var(--blue) 50%, #fff 50%);
  background-size: 200% 100%;
  transition: all 0.4s ease;
  background-position: right bottom;
  color: var(--blue) !important;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  width: 150px;
  text-align: center;
  padding-block: 15px;
  outline: 2px solid var(--blue);
  border-radius: 5px;
}

.CTA:hover {
  background-position: left top;
  color: #fff !important;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
