.footer {
  background-color: var(--blue);
  width: 100%;
  padding-block: 10px;
  padding: 30px;
}

.footer h2 {
  color: white;
  font-size: 16px;
  text-align: center;
}

.footer__inputs input,
textarea {
  /* background-color: black; */
  width: 100%;
  margin-block: 8px;
  border-radius: 5px;
  border: none;
  padding: 5px;
}

.secondBTN {
  /* margin-top: 100px; */
  width: 100%;
  text-align: center;
}

.secondBTN button {
  border: 1px solid (var(--secondary));

  background: linear-gradient(to right, var(--secondary) 50%, #fff 50%);
  background-position: 100% 100%;
  background-size: 200% 100%;
  color: var(--blue);
  font-size: 14px;
  outline: 2px solid var(--blue);
  padding-block: 6px;
  padding-inline: 10px;
  text-align: start;
  text-align: center;
  transition: all 0.4s ease;
  width: 100px;
}

.footer__inputs-enquiry {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer__inputs h3 {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  margin-block: 10px;
}

.footer__inputs-enquiry-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer__inputs-enquiry-item input {
  width: 10%;
}

.footer__inputs-enquiry-item p {
  margin: 0 !important;
  flex: 1;
  color: #fff;
}

.footer__info-cert {
  margin-top: 15px;
  text-align: center;
}

.footer__info-cert a {
  color: #fff;
  /* text-decoration: none; */
}

.footer__info-cert img {
  width: 100%;
}

.footer__info-icons {
  display: flex;
  justify-content: space-between;
}

.footer hr {
  height: 10px;
}

.creators {
  color: #fff !important;
}

/* a:hover {
  color: #fff;
} */

.creators span {
  font-size: 10px;
}

.footer__copy-links {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer__copy-links a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  transition: 0.3s;
}

.checkk {
  transform: scale(1.3);
}

@media (min-width: 768px) {
  .desdes {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .footer__inputs {
    width: 51%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .footer__inputs h2 {
    text-align: start;
    margin-bottom: 40px;
    font-size: 20px;
  }

  .footer h3 {
    margin: unset;
    margin-bottom: 40px;
    font-size: 20px;
  }

  .footer textarea {
    margin-block: 20px;
  }

  .secondBTN {
    text-align: start;
  }

  .footer__info {
    /* flex: 1; */
    width: 25%;
  }

  .footer__copy {
    display: flex;
    justify-content: space-between;
  }

  .footer__copy-links {
    gap: 20px;
  }

  .footer__logo {
    width: 60px;
    height: 60px;
  }

  .img-con {
    display: flex;
    justify-content: flex-end;
  }
  .footer__info-cert {
    text-align: end;
    color: white;
  }

  .footer__info-cert img {
    margin-top: 20px;
  }

  .footer__copy-links a:hover {
    color: var(--secondary);
    font-size: 18px;
  }
}
