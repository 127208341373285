.about__content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-block: 30px;
}
/* picture*/
.about__content-img {
  width: 100%;
}

.about__content-img img {
  width: 100%;
  height: 100%;
}

/* .pdff {
  width: 100%;
} */

.pdff img {
  width: 100%;
}

.about__content-text {
  text-align: center;
}

.about__content-text p {
  margin-inline: 10px;
}

.founder {
  padding-top: 2%;
  padding-bottom: 1%;
}

h2 span {
  font-size: 22px;
}

@media (min-width: 801px) {
  .about__content {
    flex-direction: row;
    gap: 20px;
  }
  .invert {
    flex-direction: row-reverse;
  }

  .about__content-text {
    text-align: unset;
  }

  .about__content-text p {
    margin-inline: unset;
  }

  .about__content-img {
    width: 50%;
  }

  .about__content-text {
    width: 50%;
    margin-inline: unset;
  }

  .pdff img {
    width: 50%;
  }
}
