.whatsapp {
  position: fixed;
  bottom: 20px;
  right: 15px;
  width: 50px;
  height: 50px;
  background-color: rgb(0, 160, 0);
  padding: 10px;
  border-radius: 50%;
  z-index: 1;
}

.whatsapp img {
  width: 100%;
  object-fit: contain;
}
