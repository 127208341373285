.panel {
  position: absolute;
  top: 0;
  height: 100vh;
  z-index: -1;
  width: 80px;
  background-color: var(--blue);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 60px;
  z-index: 1;
}

.social {
  cursor: pointer;
  transition: 0.5s;
  border-radius: 5px;
  width: 32px;
  height: 32px;
  margin-block: 8px;
  padding: 5px;
}

.social__link {
  margin-inline: auto;
  z-index: 10000000;
}

.social:hover {
  background-color: var(--secondary);
  width: 35px;
  height: 35px;
}
