/* Mobile */
.details {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 30px;
}

.details__left h2 {
  margin-top: 15px;
  text-align: center;
}

.details__left p {
  text-align: center;
  margin-top: 15px;
}

.details__left-btn {
  margin-top: 30px;
  text-align: center;
}

.details__right-img {
  height: 100%;
}

.details__right-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Desktop */
@media (min-width: 801px) {
  .details {
    flex-direction: row;
    gap: 20px;
  }

  .details__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  .details__left h2 {
    text-align: unset;
    margin-top: unset;
    font-size: 48px;
  }

  .details__left p {
    margin-inline: unset;
    text-align: unset;
    margin-top: unset;
    font-weight: 20px;
    font-size: 18px;
  }

  .details__left-btn {
    text-align: unset;
    width: 100%;
  }

  .details__right {
    flex: 1;
  }
}
