.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 93vh;
  /* background-color: green; */
}

.error__img img {
  width: 100%;
}

.error__top {
  text-align: center;
}

.error__bottom {
  text-align: center;
  /* color: greenyellow; */
  width: 100%;
}

.error__btn {
  border: 1px solid var(--blue);
  color: var(--blue);
  padding: 7px 35px;
  border-radius: 8px;
  text-decoration: none;
}

.error__page {
  background-color: #fff;
}

.error__top {
  font-weight: 500;
}

.error__text {
  font-weight: 400;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

@media (min-width: 641px) {
  .error__top {
    font-size: 54px;
  }

  .error__text {
    font-size: 34px;
  }
}
