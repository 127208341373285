.products {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 60px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.pageName {
  margin-top: 40px;
  margin-bottom: 60px;
}

.pageName h2 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 58px;
}

.product {
  background-size: cover;
  background-repeat: no-repeat;
  height: 330px;
  /* width: 256px; */
  max-width: 275px;
  background-position: center;
  position: relative;
  margin-inline: auto;
  background-color: black;
}

.product img {
  object-fit: cover;
  filter: brightness(70%);
  width: 100%;
  height: 100%;
}

.product__content {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.product__content h2 {
  margin: unset;
  color: #fff;
  margin-bottom: 10px;
}

.product__content a {
  background-color: var(--blue);
  color: #fff;
  padding: 8px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 5px;
  text-decoration: none;
  margin-top: 30px;
}

/* Shine */
.shine {
  position: relative;
  overflow: hidden;
}
.shine::before {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  content: "";
  display: block;
  height: 100%;
  left: -75%;
  position: absolute;
  top: 0;
  transform: skewX(-25deg);
  width: 50%;
  z-index: 2;
}
.shine:hover::before,
.shine:focus::before {
  animation: shine 1.3s;
  animation-fill-mode: backwards;
}
@keyframes shine {
  0% {
    left: -75%;
  }
  100% {
    left: 125%;
  }
}

.shine::before {
  background: linear-gradient(
    to right,
    fade_out(#fff, 1) 0%,
    fade_out(#fff, 0.7) 100%
  );
  content: "";
  display: block;
  height: 100%;
  left: -75%;
  position: absolute;
  top: 0;
  transform: skewX(-25deg);
  width: 50%;
  z-index: 2;
}

/* Desktop */
@media (min-width: 641px) {
  .products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .product {
    margin-inline: unset;
    width: unset;
    width: 275px;
  }
}

@media (min-width: 1201px) {
  .products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .product {
    margin-inline: unset;
    width: unset;
  }

  .products__text h1 {
    font-size: 58px;
    width: 500px;
  }
}
