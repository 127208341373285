.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  position: relative;
  height: 70px;
  position: 99;
  z-index: 2;
}

.header__logo {
  width: 200px;
  background-color: var(--blue);
  /* height: 100%; */
  display: flex;
  align-items: center;
  object-fit: cover;
}

.dropL {
  position: relative;
}

.products__dropM {
  display: none;
  /* position: absolute; */
  background-color: #fff;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  z-index: 1;
  transition: 1s;
}

.products__dropM a {
  float: none;
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dd {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dd a {
  width: 80%;
}

.span {
  border: none;
  outline: none;
  border-radius: 2px;
  padding-inline: 5px;
  padding-block: 2px;
  background-color: var(--blue);
  /* float: right; */
  margin-right: 20px;
}

.span img {
  transform: rotate(-90deg);
}

.dropL:hover .products__drop {
  display: block;
}

.drop img {
  transform: rotate(-90deg);
}

.header__logo img {
  width: 100%;
  height: 100%;
}

.header__menu {
  cursor: pointer;
}

.header__menu img {
  width: 30px;
  height: 30px;
}

.header__items {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: fixed;
  top: 0;
  right: 0;

  width: 60%;
  background-color: #fff;
  height: 100vh;
  z-index: 101;

  transform: translate3d(100%, 0, 0);
  transition: right 0.5s ease;
}

.open {
  right: 60%;
}

.activeM {
  color: var(--blue) !important;
  font-weight: 700;
}

.header__items li:first-child {
  margin-top: 60px;
}

.header__items li:not(:last-child) {
  padding-left: 10px;
  margin-bottom: 30px;
}
.number {
  background-color: var(--blue);
  color: #fff;
  padding: 10px;
}

.header__items a {
  text-decoration: none;
}

.no-scroll {
  overflow: hidden;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 1s;
  z-index: 100;
}

.header__itemsD {
  display: flex;
  gap: 20px;
  list-style-type: none;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  align-items: center;
}

.header__itemsD a {
  text-decoration: none;
  color: #fff;
  font-weight: 500px;
}

@media screen and (min-width: 776px) {
  .header__logo {
    width: 300px;
    padding-left: 10px;
  }

  .header {
    height: 100px;
    padding-right: unset;
  }

  /* .navvv {
    margin-bottom: 10px;
  } */

  .activeD::after {
    content: "";
    display: block;
    width: 70%;
    /* padding-top: 3px; */
    margin-top: -3px;
    border-bottom: 3px solid var(--secondary);
  }

  .activeD .navvv a {
    color: yellow !important;
  }

  a.activeD.navvv {
    font-weight: 700;
    /* color: var(--complementary) !important; */
  }

  .products__drop a:hover {
    background-color: var(--blue);
    color: #fff !important;
  }

  .header__itemsD .navvv:hover::after {
    content: "";
    display: block;
    width: 70%;
    /* padding-top: 10px; */
    /* padding-top: 3px; */
    margin-top: -3px;
    border-bottom: 3px solid var(--secondary);
  }

  .header__logo img {
    width: unset;
    height: unset;
  }

  .dropL {
    position: relative;
  }

  .products__drop {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .products__drop a {
    float: none;
    color: black !important;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
}
