.con {
  margin-top: 60px;
  margin-bottom: 60px;
}

.con h1 {
  color: #000 !important;
  margin-bottom: 0;
  text-align: unset !important;
}

.services {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 20px;
  gap: 40px;
  margin-top: 50px;
  width: 100%;
}

.service {
  justify-items: center;
  text-align: center;
  justify-content: space-between;
  padding-block: 20px;
  position: relative;
  outline: 1px solid var(--blue);
  transition: background-color 0.7s ease-out;
  color: #111;
}

.service:hover {
  background-color: var(--blue);
  color: #fff;
}

.bb {
  margin-top: 100px;
  position: absolute;
  margin-top: auto;
  bottom: 10px;
  width: 100%;
  left: 0;
}

.service img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: fill;
}

.service h3 {
  font-size: 20px;
  margin-top: 5px;
}

.service p {
  margin-top: 10px;
  margin-bottom: 70px;
  margin-inline: 10px;
}

.service a {
  margin-top: 10px;
  padding-block: 6px;
  padding-inline: 10px;
  font-size: 14px;
  width: 100px;
  text-align: start;
  color: var(--blue);
  background: linear-gradient(to right, var(--secondary) 50%, #fff 50%);
  background-size: 200% 100%;
  transition: all 0.4s ease;
  background-position: right bottom;
  text-align: center;
  outline: 2px solid var(--blue);
}

.service a:hover {
  color: var(--blue);
  background-position: left top;

  outline: unset;
}

@media (min-width: 680px) {
  .services {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (min-width: 790px) {
  .con h1 {
    font-size: 48px;
  }

  .services {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
  }

  .service {
    padding: 10px;
    border-radius: 5px;
  }

  .service a {
    margin-top: 10px;
    padding-block: 6px;
    font-size: 12px;
    width: 80px;
    width: auto;
  }
}
