.hero {
  width: 100%;
  height: 50vh;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.carrr {
  width: 100% !important;
}

.carousel__images {
  width: 100vw;
  height: 50vh;
  object-fit: cover;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  padding: 20px;
}

.carousel-control-next,
.carousel-control-prev {
  width: 40px;
}
.carrr {
  position: absolute;
  top: 0;
}

.carousel-caption {
  bottom: unset;

  top: 50%;
  transform: translateY(-50%);
}

.carousel-caption h3 {
  font-size: 30px;
}

.home__title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 100%;
}

.home__title h1 {
  background-color: rgba(0, 0, 0, 0.6);
}

.home h1 {
  font-size: 30px;
  width: 200px;
  color: #fff;
  text-align: center;
}

.home__chevron {
  position: relative;
  z-index: 100000;
  top: 25vh;
  transform: translateY(-50%);
  height: 100%;
  cursor: pointer;
}

.tete {
  position: absolute;
  width: 100%;
  height: 50vh;
}

.chev {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 10px;
}

.chev img {
  width: 25px;
  height: 25px;
}

.home__chevron-left {
  left: 0;
  top: 0;
}

.home__chevron-right {
  right: 0;
}

.carousel__text-links {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.carousel__text-links a {
  /* margin-inline: 20px; */
  /* padding-inline: 5px; */
  width: 90px;
  padding-block: 10px;
  background-color: rgb(0, 0, 0);
  text-decoration: none;
  color: #fff;
  transition: 0.4s;
}

/* .carousel__text-links a:hover {
  background-color: hsla(214, 77%, 48%, 1);
} */

/* .other-btn:hover {
  background-color: hsla(11, 57%, 60%) !important;
} */

@media screen and (min-width: 641px) {
  .hero {
    height: 100vh;
    background-size: cover;
  }

  .home h1 {
    font-size: 58px;
    width: 400px;
  }

  .home__title {
    height: 85%;
  }

  .home__chevron {
    top: 50vh;
  }

  .tete {
    height: 100vh;
  }

  .carousel__images {
    height: 100vh;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 80px;
    height: 45px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .carousel-caption h3 {
    font-size: 58px;
  }

  .carousel-caption p {
    font-size: 30px;
  }

  .carousel__text-links a {
    margin-inline: 20px;
    padding-inline: 25px;
    padding-block: 15px;
    /* border-radius: 5px; */
  }

  .carousel__text-links {
    display: unset;
  }
}
