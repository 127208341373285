.about__intro {
  background-image: url("../../images/construction.webp");
  height: 280px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about__intro h1 {
  color: #fff;
  margin-top: 80px;
  font-size: 48px;
}
